<template>
    <section class="section">
        <ValidationObserver v-slot="{ handleSubmit }">
            <v-form class="mt-15" @submit.prevent="handleSubmit(onSubmit)">
                <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" max-height="90vh">
                    <v-container>
                        <v-tabs center-active>
                            <v-tab>{{ $t('CalendarProfile') }}</v-tab>
                            <v-tab :disabled="isLoading">{{ $t('Trips') }}</v-tab>
                            <v-tab-item>
                                <v-row class="mt-10">
                                    <v-col cols="12" md="12">
                                        <v-row>
                                            <v-col cols="12" md="4">
                                                <ValidationProvider
                                                    :name="$t('StartDate')"
                                                    rules="required"
                                                    v-slot="{ errors }">
                                                    <v-menu
                                                        ref="startDateMenu"
                                                        v-model="startDateMenu"
                                                        :close-on-content-click="false"
                                                        :return-value.sync="form.start_date"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                v-model="form.start_date"
                                                                :error="errors[0] ? true : false"
                                                                :disabled="isDisabled"
                                                                :label="$t('StartDate')"
                                                                readonly
                                                                filled
                                                                hide-details="auto"
                                                                shaped
                                                                v-bind="attrs"
                                                                v-on="on"></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="form.start_date" no-title scrollable>
                                                            <v-spacer></v-spacer>
                                                            <v-btn text color="primary" @click="startDateMenu = false">
                                                                Cancel
                                                            </v-btn>
                                                            <v-btn
                                                                text
                                                                color="primary"
                                                                @click="$refs.startDateMenu.save(form.start_date)">
                                                                OK
                                                            </v-btn>
                                                        </v-date-picker>
                                                    </v-menu>

                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" md="4">
                                                <ValidationProvider
                                                    :name="$t('EndDate')"
                                                    rules="required"
                                                    v-slot="{ errors }">
                                                    <v-menu
                                                        ref="endDateMenu"
                                                        v-model="endDateMenu"
                                                        :close-on-content-click="false"
                                                        :return-value.sync="form.end_date"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                v-model="form.end_date"
                                                                :error="errors[0] ? true : false"
                                                                :disabled="isDisabled"
                                                                :label="$t('EndDate')"
                                                                readonly
                                                                filled
                                                                hide-details="auto"
                                                                shaped
                                                                v-bind="attrs"
                                                                v-on="on"></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="form.end_date"
                                                            :min="form.start_date"
                                                            no-title
                                                            scrollable>
                                                            <v-spacer></v-spacer>
                                                            <v-btn text color="primary" @click="endDateMenu = false">
                                                                Cancel
                                                            </v-btn>
                                                            <v-btn
                                                                text
                                                                color="primary"
                                                                @click="$refs.endDateMenu.save(form.end_date)">
                                                                OK
                                                            </v-btn>
                                                        </v-date-picker>
                                                    </v-menu>

                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" md="4">
                                                <ValidationProvider
                                                    :name="$tc('Sunday')"
                                                    rules="required"
                                                    v-slot="{ errors }">
                                                    <v-select
                                                        :error="errors[0] ? true : false"
                                                        :disabled="isDisabled"
                                                        :items="daysAvailability"
                                                        filled
                                                        shaped
                                                        item-value="value"
                                                        item-text="name"
                                                        hide-details
                                                        v-model="form.sunday"
                                                        :label="$tc('Sunday')"></v-select>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" md="4">
                                                <ValidationProvider
                                                    :name="$tc('Monday')"
                                                    rules="required"
                                                    v-slot="{ errors }">
                                                    <v-select
                                                        :error="errors[0] ? true : false"
                                                        :disabled="isDisabled"
                                                        :items="daysAvailability"
                                                        filled
                                                        shaped
                                                        item-value="value"
                                                        item-text="name"
                                                        hide-details
                                                        v-model="form.monday"
                                                        :label="$tc('Monday')"></v-select>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" md="4">
                                                <ValidationProvider
                                                    :name="$tc('Tuesday')"
                                                    rules="required"
                                                    v-slot="{ errors }">
                                                    <v-select
                                                        :error="errors[0] ? true : false"
                                                        :disabled="isDisabled"
                                                        :items="daysAvailability"
                                                        filled
                                                        shaped
                                                        item-value="value"
                                                        item-text="name"
                                                        hide-details
                                                        v-model="form.tuesday"
                                                        :label="$tc('Tuesday')"></v-select>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" md="4">
                                                <ValidationProvider
                                                    :name="$tc('Wednesday')"
                                                    rules="required"
                                                    v-slot="{ errors }">
                                                    <v-select
                                                        :error="errors[0] ? true : false"
                                                        :disabled="isDisabled"
                                                        :items="daysAvailability"
                                                        filled
                                                        shaped
                                                        item-value="value"
                                                        item-text="name"
                                                        hide-details
                                                        v-model="form.wednesday"
                                                        :label="$tc('Wednesday')"></v-select>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" md="4">
                                                <ValidationProvider
                                                    :name="$tc('Thursday')"
                                                    rules="required"
                                                    v-slot="{ errors }">
                                                    <v-select
                                                        :error="errors[0] ? true : false"
                                                        :disabled="isDisabled"
                                                        :items="daysAvailability"
                                                        filled
                                                        shaped
                                                        item-value="value"
                                                        item-text="name"
                                                        hide-details
                                                        v-model="form.thursday"
                                                        :label="$tc('Thursday')"></v-select>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" md="4">
                                                <ValidationProvider
                                                    :name="$tc('Friday')"
                                                    rules="required"
                                                    v-slot="{ errors }">
                                                    <v-select
                                                        :error="errors[0] ? true : false"
                                                        :disabled="isDisabled"
                                                        :items="daysAvailability"
                                                        filled
                                                        shaped
                                                        item-value="value"
                                                        item-text="name"
                                                        hide-details
                                                        v-model="form.friday"
                                                        :label="$tc('Friday')"></v-select>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" md="4">
                                                <ValidationProvider
                                                    :name="$tc('Saturday')"
                                                    rules="required"
                                                    v-slot="{ errors }">
                                                    <v-select
                                                        :error="errors[0] ? true : false"
                                                        :disabled="isDisabled"
                                                        :items="daysAvailability"
                                                        filled
                                                        shaped
                                                        item-value="value"
                                                        item-text="name"
                                                        hide-details
                                                        v-model="form.saturday"
                                                        :label="$tc('Saturday')"></v-select>
                                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-tab-item>
                            <v-tab-item>
                                <div class="d-flex mt-8">
                                    <v-text-field
                                        expanded
                                        v-model="searchTripsQuery"
                                        @change="searchTrips()"
                                        :placeholder="$t('Search')"
                                        outlined
                                        hide-details
                                        dense
                                        :prepend-inner-icon="mdiMagnify"
                                        @keydown.enter.prevent="searchTrips"
                                        class="user-search me-3 mb-4">
                                    </v-text-field>
                                </div>
                                <ValidationProvider
                                    :name="$t('Trips')"
                                    rules="required"
                                    v-slot="{ errors }"
                                    ref="trips">
                                    <v-data-table
                                        show-select
                                        calculate-widths
                                        :items-per-page="5"
                                        :footer-props="{
                                            'items-per-page-options': [5, 10, 15, 20],
                                            'items-per-page-text': $t('RowsPerPage'),
                                        }"
                                        :header-props="{ 'sort-by-text': $t('SortBy') }"
                                        :headers="tripsTableColumns"
                                        :items="tripListTable"
                                        :server-items-length="totalTripListTable"
                                        v-model="form.trips"
                                        name="trips"
                                        required
                                        :options.sync="tripOptions"
                                        @update:page="paginationHandle">
                                    </v-data-table>
                                    <span class="caption error--text mb-10">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </v-tab-item>
                        </v-tabs>
                    </v-container>
                </v-sheet>
                <v-app-bar height="80" absolute color="white" elevate-on-scroll scroll-target="#scrolling-techniques-7">
                    <v-btn @click="$router.go(-1)" class="square-button mx-3" color="grey-light">
                        <v-icon large color="grey">
                            {{ mdiChevronLeft }}
                        </v-icon>
                    </v-btn>
                    <v-toolbar-title v-if="!addCalendar">
                        <p class="text-h5 font-weight-semibold black--text mt-1 d-none d-md-flex d-lg-flex d-xl-flex">
                            {{ $t('CalendarProfile') }}
                        </p>
                        <p class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none">
                            {{ $t('CalendarProfile') }}
                        </p>
                    </v-toolbar-title>
                    <v-toolbar-title v-else>
                        <p class="text-h5 font-weight-semibold black--text mt-1 d-none d-md-flex d-lg-flex d-xl-flex">
                            {{ $t('AddCalendar') }}
                        </p>
                        <p class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none">
                            {{ $t('AddCalendar') }}
                        </p>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <div v-if="!addCalendar">
                        <v-btn type="submit" v-show="!isDisabled" :loading="isLoading" class="mx-3" color="primary">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="white">
                                {{ mdiCheck }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="ehite">
                                {{ mdiCheck }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Save') }}</span>
                        </v-btn>
                        <v-btn v-show="!isDisabled" @click="isDisabled = true" outlined class="mx-3" color="error">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="error">
                                {{ mdiClose }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="error">
                                {{ mdiClose }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Cancel') }}</span>
                        </v-btn>
                        <v-btn v-show="isDisabled" @click="editInformation" class="mx-3" color="grey-light">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="grey">
                                {{ mdiFileDocumentEditOutline }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="grey">
                                {{ mdiFileDocumentEditOutline }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Edit') }}</span>
                        </v-btn>

                        <v-dialog transition="dialog-top-transition" max-width="600">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-show="isDisabled" class="mx-3" color="grey-light" v-bind="attrs" v-on="on">
                                    <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="grey">
                                        {{ mdiTrashCanOutline }}
                                    </v-icon>
                                    <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="grey">
                                        {{ mdiTrashCanOutline }}
                                    </v-icon>
                                    <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Delete') }}</span>
                                </v-btn>
                            </template>
                            <template v-slot:default="dialog">
                                <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/delete.png"></v-img>
                                <v-card>
                                    <v-card-text class="text-center">
                                        <p class="title font-weight-semibold black--text mt-12">
                                            {{ $t('Alerts.Sure') }}
                                        </p>
                                    </v-card-text>
                                    <v-card-text class="text-center">
                                        <p class="body-2">
                                            {{ $t('Alerts.SureDeleteCalendar') }}
                                            <strong class="black--text"></strong> ?<br />
                                            {{ $t('Alerts.Irreversible') }}
                                        </p>
                                    </v-card-text>

                                    <v-card-actions class="justify-end mt-4">
                                        <v-btn
                                            @click="deleteCalendar"
                                            :loading="isLoading"
                                            color="error"
                                            class="px-5"
                                            >{{ $t('Buttons.Delete') }}</v-btn
                                        >
                                        <v-btn text @click="dialog.value = false" :loading="isLoading">{{
                                            $t('Buttons.Cancel')
                                        }}</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </template>
                        </v-dialog>
                    </div>
                    <v-btn v-else type="submit" :loading="isLoading" class="mx-3" color="primary">
                        <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="white">
                            {{ mdiCheck }}
                        </v-icon>
                        <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="white">
                            {{ mdiCheck }}
                        </v-icon>
                        <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Conclude') }}</span>
                    </v-btn>
                </v-app-bar>
            </v-form>
        </ValidationObserver>
        <v-snackbar :timeout="2000" top v-model="createSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.AddCalendarSuccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="updateSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.UpdateCalendarSuccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="deleteSuccess" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/correct.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Success') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.DeleteCalendarSuccess') }}</p>
        </v-snackbar>
        <v-snackbar :timeout="2000" top v-model="isError" color="white">
            <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/failed.png"></v-img>
            <p class="text-center title font-weight-semibold black--text mt-5">{{ $t('Alerts.Error') }}</p>
            <p class="text-center black--text">{{ $t('Alerts.ErrorMessage') }}</p>
        </v-snackbar>
    </section>
</template>

<script>
    import {
        mdiChevronLeft,
        mdiFileDocumentEditOutline,
        mdiInformationOutline,
        mdiPlus,
        mdiTrashCanOutline,
        mdiCheck,
        mdiClose,
        mdiChevronRight,
        mdiMagnify,
    } from '@mdi/js';
    import _ from 'lodash';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import useTripsList from '@/views/route/trips-list/useTripsList';

    const initialState = () => ({
        isLoading: false,
        form: {
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
            sunday: '',
            calendar_dates: [],
            trips: [],
            start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
            end_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
        },

        isLoadingTable: true,
    });

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,
        },

        data() {
            return {
                ...initialState(),
                isDisabled: true,
                valid: false,

                addCalendar: false,

                isError: false,
                createSuccess: false,
                updateSuccess: false,
                deleteSuccess: false,
                startDateMenu: false,
                endDateMenu: false,
                searchTripsQuery: null,
                tripOptions: {
                    page: 1,
                },
                daysAvailability: [
                    { name: 'NotAlwaysAvailable', value: 'O0' },
                    { name: 'AlwaysAvailable', value: 'O1' },
                ],
                tripQuery: {},
            };
        },
        created() {
            this.tripQuery = this.$store.getters['app-routes/getTripsQuery'];
            this.fetchTripList();
            for (let index = 0; index < this.daysAvailability.length; index++) {
                this.daysAvailability[index].name = this.$t(this.daysAvailability[index].name);
            }
            if (this.$route.params.id != 'add') {
                this.addCalendar = false;
                this.isDisabled = true;
                this.getCalendar();
            } else {
                this.addCalendar = true;
                this.isDisabled = false;
            }
        },

        methods: {
            editInformation() {
                this.isDisabled = !this.isDisabled;
            },

            getCalendar() {
                this.isLoading = true;
                this.$store
                    .dispatch('app-routes/fetchCalendar', this.$route.params.id)
                    .then((response) => {
                        this.isLoading = false;
                        console.log(response);
                        this.form = response.data.data;
                        /* this.form.trips = [response.data.data.trips]; */
                    })
                    .catch((error) => {
                        console.log(error);
                        if (error.response.status === 404) {
                            this.form = {};
                        }
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },

            async onSubmit() {
                if (this.addCalendar) {
                    await this.onAddCalendar();
                } else await this.onUpdateCalendar();

                setTimeout(() => {
                    this.$router.push({ name: 'RoutesList' });
                }, 1500);
            },

            async onAddCalendar() {
                this.isLoading = true;
                console.log(this.form);

                this.form.trips = this.form.trips.map((item) => {
                    return item.id;
                });

                await this.$store
                    .dispatch('app-routes/addCalendar', this.form)
                    .then(async (response) => {
                        this.createSuccess = true;
                    })
                    .catch((error) => {
                        this.isError = true;
                        console.log(error);
                    })
                    .finally(() => {
                        this.isLoading = false;
                        this.isDisabled = true;
                    });
            },

            async onUpdateCalendar() {
                this.isLoading = true;

                await this.$store
                    .dispatch('app-routes/updateCalendar', this.form)
                    .then(async (response) => {
                        this.updateSuccess = true;
                    })
                    .catch((error) => {
                        this.isError = true;
                        console.log(error);
                    })
                    .finally(() => {
                        this.isLoading = false;
                        this.isDisabled = true;
                    });
            },

            deleteCalendar() {
                this.isLoading = true;
                this.$store
                    .dispatch('app-routes/deleteCalendar', this.$route.params.id)
                    .then((response) => {
                        this.deleteSuccess = true;
                        setTimeout(() => {
                            this.$router.push({ name: 'RoutesList' });
                        }, 1500);
                    })
                    .catch((error) => {
                        this.isError = true;
                        console.log(error);
                    });
            },

            searchTrips() {
                this.isLoading = true;
                this.$store.commit('app-routes/setTripSearch', this.searchTripsQuery);
                this.tripQuery = this.$store.getters['app-routes/getTripsQuery'];
                this.tripQuery.pagination.page = 1;
                this.fetchTripList();
                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            },

            paginationHandle(e) {
                console.log(this.tripQuery);
                if (this.tripOptions.page > this.tripQuery.pagination.page) {
                    this.tripQuery.pagination.page = this.tripOptions.page;
                    this.$store.commit('app-routes/setRoutesQuery', this.tripQuery);
                    this.fetchTripList();
                }
            },
        },

        setup() {
            const { tripsTableColumns, tripListTable, totalTripListTable, fetchTripList } = useTripsList();
            return {
                mdiInformationOutline,
                mdiFileDocumentEditOutline,
                mdiCheck,
                mdiClose,
                mdiTrashCanOutline,
                mdiPlus,
                mdiChevronLeft,
                mdiChevronRight,
                mdiMagnify,

                tripsTableColumns,
                tripListTable,
                totalTripListTable,
                fetchTripList,
            };
        },
    };
</script>

<style lang="scss" scoped>
    .v-application.theme--light .v-tabs:not(.v-tabs--vertical) {
        box-shadow: 0 0px 0px 0px rgb(94 86 105 / 42%) !important;
    }

    .route-color-picker {
        position: absolute;
        z-index: 10;
        display: none;
    }

    .route-text-color-picker {
        position: absolute;
        z-index: 10;
        display: none;
    }
</style>
